import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3572675178/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`iOS SDK v2 Releases`}</h1>
    <h2>{`2.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Migrate to Enigmatic-Smile GitHub organization`}</li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <ul>
      <li parentName="ul">{`No relevant changes for this product`}</li>
    </ul>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Add a few more card verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Removed card scanning so `}<inlineCode parentName="li">{`Fidel.autoScan`}</inlineCode>{` has been removed.`}</li>
      <li parentName="ul">{`Changes in the `}<inlineCode parentName="li">{`Fidel`}</inlineCode>{` class:`}
        <ul parentName="li">
          <li parentName="ul">{`Made the `}<inlineCode parentName="li">{`companyName`}</inlineCode>{` property mandatory`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.apiKey`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`Fidel.sdkKey`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.programId`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`Fidel.programID`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.termsConditionsURL`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`Fidel.termsAndConditionsUrl`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.privacyURL`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`privacyPolicyUrl`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.present(...)`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`Fidel.start(...)`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Instead of providing just one type of result, `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{`, the SDK now provides a generic `}<inlineCode parentName="li">{`FidelResult`}</inlineCode>{` enum which incorporates different type of results: `}<inlineCode parentName="li">{`.enrollementResult`}</inlineCode>{` and `}<inlineCode parentName="li">{`.error`}</inlineCode>{`. Each one of them provides the appropriate object for developers: `}<inlineCode parentName="li">{`EnrollementResult`}</inlineCode>{` and `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Introduced a new property called `}<inlineCode parentName="li">{`onResult`}</inlineCode>{` which retains a closure to be called every time a result is available.`}</li>
      <li parentName="ul">{`Changes in the old `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{` which is now `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`id`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardID`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The data type for `}<inlineCode parentName="li">{`created`}</inlineCode>{` property is now a `}<inlineCode parentName="li">{`Date`}</inlineCode>{`, not a `}<inlineCode parentName="li">{`String`}</inlineCode>{`. Renamed property to `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The data type for `}<inlineCode parentName="li">{`scheme `}</inlineCode>{`is now `}<inlineCode parentName="li">{`CardScheme`}</inlineCode>{`, not `}<inlineCode parentName="li">{`String`}</inlineCode>{`. Renamed property to `}<inlineCode parentName="li">{`cardScheme`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed property `}<inlineCode parentName="li">{`programId`}</inlineCode>{` to `}<inlineCode parentName="li">{`programID`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed property `}<inlineCode parentName="li">{`live`}</inlineCode>{` to `}<inlineCode parentName="li">{`isLive`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`cardFirstNumbers`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`lastNumbers`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`cardLastNumbers`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`expYear`}</inlineCode>{` was renamed to card `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`expMonth`}</inlineCode>{` should be renamed to `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`expDate`}</inlineCode>{` string property was removed. `}</li>
          <li parentName="ul"><inlineCode parentName="li">{`countryCode`}</inlineCode>{` was  renamed to `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode>{` and its data type is now `}<inlineCode parentName="li">{`Country`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`accountId`}</inlineCode>{` to `}<inlineCode parentName="li">{`accountID`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Changes to the LinkResultError class:`}
        <ul parentName="li">
          <li parentName="ul">{`Renamed to FidelError. `}</li>
          <li parentName="ul">{`The date property now has a `}<inlineCode parentName="li">{`Date`}</inlineCode>{` data type, not `}<inlineCode parentName="li">{`String`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The (error) code property is now an enum. It has 2 cases: `}<inlineCode parentName="li">{`.enrollmentError`}</inlineCode>{` and `}<inlineCode parentName="li">{`.verificationError`}</inlineCode>{` (the last one not relevant to the Transaction Select API)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Dropped support for Objective-C.`}</li>
    </ul>
    <h1>{`iOS SDK v1 Releases`}</h1>
    <h2>{`1.10.3`}</h2>
    <ul>
      <li parentName="ul">{`Updated the consent text for United States and Canadian issued cards.`}</li>
    </ul>
    <h2>{`1.10.2`}</h2>
    <ul>
      <li parentName="ul">{`Update "Fidel" with "Fidel API" in the consent text and in the code documentation.`}</li>
    </ul>
    <h2>{`1.10.1`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel API logo`}</li>
    </ul>
    <h2>{`1.10.0`}</h2>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`defaultSelectedCountry`}</inlineCode>{` property which sets the country that will be selected by default, when opening the card enrollment screen.`}</li>
    </ul>
    <h2>{`1.9.0`}</h2>
    <ul>
      <li parentName="ul">{`Removed the card scanning confirmation screen. Users can confirm their card information by checking the information in the Fidel card enrollment screen.`}</li>
    </ul>
    <h2>{`1.8.8`}</h2>
    <ul>
      <li parentName="ul">{`Fixed the bug that does not allow enrollment for cards that expire in the current month.`}</li>
    </ul>
    <h2>{`1.8.7`}</h2>
    <ul>
      <li parentName="ul">{`Add the United Arab Emirates country of issuance option`}</li>
    </ul>
    <h2>{`1.8.6`}</h2>
    <ul>
      <li parentName="ul">{`Add more documentation for the Fidel SDK APIs`}</li>
      <li parentName="ul">{`Deprecate `}<inlineCode parentName="li">{`mapped`}</inlineCode>{`, `}<inlineCode parentName="li">{`type`}</inlineCode>{`, `}<inlineCode parentName="li">{`updated`}</inlineCode>{` fields from the `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{` class.`}</li>
    </ul>
    <h2>{`1.8.5`}</h2>
    <ul>
      <li parentName="ul">{`Use the XCFramework package format for the Fidel SDK`}</li>
      <li parentName="ul">{`Compile the package with Swift 5.4`}</li>
      <li parentName="ul">{`Can run the SDK in simulators on M1 machines.`}</li>
    </ul>
    <h2>{`1.8.5-rc2`}</h2>
    <ul>
      <li parentName="ul">{`Use the XCFramework package format for the Fidel SDK`}</li>
      <li parentName="ul">{`Compile the package with Swift 5.4`}</li>
      <li parentName="ul">{`Can run the SDK in simulators on M1 machines.`}</li>
    </ul>
    <h2>{`1.8.5-rc1`}</h2>
    <ul>
      <li parentName="ul">{`Use the XCFramework package format for the Fidel SDK`}</li>
      <li parentName="ul">{`Compile the package with Swift 5.4`}</li>
      <li parentName="ul">{`Does not yet support running on Simulators with the most common `}<inlineCode parentName="li">{`Release`}</inlineCode>{` configurations.`}</li>
    </ul>
    <h2>{`1.8.4`}</h2>
    <ul>
      <li parentName="ul">{`If available, the LinkResult object now includes the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field. So, if in the Fidel Dashboard, under the your security settings, you allow showing the first numbers of the linked card numbers, the information will be available in the LinkResult object too. If you do not allow showing the first numbers in the linking result, the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field will return `}<inlineCode parentName="li">{`"******"`}</inlineCode>{` (just like the object which the Fidel API returns).`}</li>
    </ul>
    <h2>{`1.8.3`}</h2>
    <ul>
      <li parentName="ul">{`Compiled the SDK for Swift 5.3.1`}</li>
      <li parentName="ul">{`Now the SDK allows you to select multiple allowed countries from which the user can pick. Please check the docs for the new `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property. If your project is an Objective-C project, check the `}<inlineCode parentName="li">{`objc_allowedCountries`}</inlineCode>{` property.`}</li>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property. To set a default country and not allow the user to pick the country, set a single country in the new `}<inlineCode parentName="li">{`Fidel.allowedCountries`}</inlineCode>{` array.`}</li>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`.noDefault`}</inlineCode>{` option from the `}<inlineCode parentName="li">{`Country`}</inlineCode>{` enum.`}</li>
    </ul>
    <h2>{`1.8.2`}</h2>
    <ul>
      <li parentName="ul">{`Compiled the SDK for Swift 5.3`}</li>
      <li parentName="ul">{`Projects with this version of the Fidel SDK integrated will:`}
        <ul parentName="li">
          <li parentName="ul">{`work fine if you build for a `}<strong parentName="li">{`real device`}</strong>{`, with both `}<inlineCode parentName="li">{`Debug`}</inlineCode>{` and `}<inlineCode parentName="li">{`Release`}</inlineCode>{` configurations. It works on all architectures.`}</li>
          <li parentName="ul">{`not build on `}<strong parentName="li">{`Simulators`}</strong>{`, with the `}<inlineCode parentName="li">{`arm64`}</inlineCode>{` architecture. We're working on eliminating this inconvenience.`}</li>
          <li parentName="ul">{`To avoid this incovenience, when installing this version with Cocoapods, the following build settings will be added to your project:`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`s.pod_target_xcconfig = { 'EXCLUDED_ARCHS[sdk=iphonesimulator*]' => 'arm64' }
s.user_target_xcconfig = { 'EXCLUDED_ARCHS[sdk=iphonesimulator*]' => 'arm64' }
`}</code></pre>
    <p>{`So if you install this version with Cocoapods, you'll be able to build on both Simulators and real devices.`}</p>
    <p>{`You can confidently launch your apps to the AppStore. We only excluded the architecture for Simulators.`}</p>
    <h2>{`1.8.1`}</h2>
    <ul>
      <li parentName="ul">{`Fixed bug that did not set and highlight the privacy policy link for countries outside of North-America.`}</li>
      <li parentName="ul">{`The SDK compiles for simulators from Objective-C projects too.`}</li>
    </ul>
    <h2>{`1.8.0`}</h2>
    <ul>
      <li parentName="ul">{`Localised the SDK for French and Swedish users.`}</li>
      <li parentName="ul">{`The terms & conditions text now adjusts to the card scheme name that the user inputs (Visa, MasterCard or Amex).`}</li>
      <li parentName="ul">{`If you set the default country of the SDK to USA or Canada (with `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property) or, if you do not set a default country, the terms and conditions text will adjust depending on the country you have set. For USA & Canada, the following would be an example Terms & Conditions text, for Cashback Inc (an example company):`}</li>
    </ul>
    <p><em parentName="p">{`By submitting your card information and checking this box, you authorize Visa to monitor and share transaction data with Fidel (our service provider) to participate in  program. You also acknowledge and agree that Fidel may share certain details of your qualifying transactions with Cashback Inc to enable your participation in  program and for other purposes in accordance with the Cashback Inc Terms and Conditions, Cashback Inc privacy policy and Fidel’s Privacy Policy. You may opt-out of transaction monitoring on the linked card at any time by contacting support.`}</em></p>
    <p>{`For the rest of the world:`}</p>
    <p><em parentName="p">{`I authorise Visa to monitor my payment card to identify transactions that qualify for a reward and for Visa to share such information with Cashback Inc, to enable my card linked offers and target offers that may be of interest to me. For information about Cashback Inc privacy practices, please see the privacy policy. You may opt-out of transaction monitoring on the payment card you entered at any time by contacting support.`}</em></p>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programName`}</inlineCode>{` and `}<inlineCode parentName="li">{`termsConditionsURL`}</inlineCode>{` properties. They are used when building the new USA / Canada specific terms and conditions text that the user must agree with, before linking a card. If you set the `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property to a USA or Canada, it's mandatory for you to provide your terms and conditions URL via `}<inlineCode parentName="li">{`Fidel.termsConditionsURL`}</inlineCode>{`. If you do not provide it, you will receive an error when you try to open Fidel's linking screen.`}</li>
      <li parentName="ul">{`Fixed some small bugs.`}</li>
    </ul>
    <h2>{`1.7.1`}</h2>
    <ul>
      <li parentName="ul">{`The Swift 5.2.2 compiled SDK now has simulator support from Objective-C projects.`}</li>
    </ul>
    <h2>{`1.7.0`}</h2>
    <ul>
      <li parentName="ul">{`The SDK compiled for Swift 5.2.2`}</li>
    </ul>
    <h2>{`1.6.2`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a small bug related to the country property of the SDK.`}</li>
    </ul>
    <h2>{`1.6.1`}</h2>
    <ul>
      <li parentName="ul">{`Compiled with Swift 5.1.2.`}</li>
      <li parentName="ul">{`Supports Xcode 11.2.1 and iOS 13.2.`}</li>
      <li parentName="ul">{`Fixed the bug that deleted the `}<inlineCode parentName="li">{`metaData`}</inlineCode>{` object after the first card linking request.`}</li>
    </ul>
    <h2>{`1.6.0`}</h2>
    <ul>
      <li parentName="ul">{`Compiled with Swift 5.1.`}</li>
      <li parentName="ul">{`Supports Xcode 11 and iOS 13.`}</li>
      <li parentName="ul">{`Has a Dark Appearance.`}</li>
    </ul>
    <h2>{`1.5.1`}</h2>
    <ul>
      <li parentName="ul">{`Added new property that lets you define the card schemes that you suppport (`}<inlineCode parentName="li">{`supportedCardSchemes`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`Generated the Objective-C `}<inlineCode parentName="li">{`Fidel-Swift.h`}</inlineCode>{` file with Xcode 10.2. Added the generated header file contents that provides support to run your Objective-C project on the simulator as well.`}</li>
    </ul>
    <h2>{`1.5.0`}</h2>
    <ul>
      <li parentName="ul">{`The Fidel iOS SDK is now compiled with Swift 5`}</li>
    </ul>
    <h2>{`1.4.4`}</h2>
    <ul>
      <li parentName="ul">{`Added the Canada country option`}</li>
    </ul>
    <h2>{`1.4.3`}</h2>
    <ul>
      <li parentName="ul">{`Add support for American Express test cards`}</li>
    </ul>
    <h2>{`1.4.2`}</h2>
    <ul>
      <li parentName="ul">{`Hidden the PayPal logo in the card scanning UI.`}</li>
      <li parentName="ul">{`Notifying in the `}<inlineCode parentName="li">{`onCardLinkFailedCallback`}</inlineCode>{` when the user cancels card linking. The `}<inlineCode parentName="li">{`error.code`}</inlineCode>{` is `}<inlineCode parentName="li">{`user-canceled`}</inlineCode>{`. The error message is `}<inlineCode parentName="li">{`User canceled card linking.`}</inlineCode></li>
      <li parentName="ul">{`Hidden the "select country" bar on all-screen iPhones.`}</li>
      <li parentName="ul">{`Added the Japan country option.`}</li>
      <li parentName="ul">{`Disabled CardIO manual card details entry forms.`}</li>
      <li parentName="ul">{`Improved testing mode user experience.`}</li>
      <li parentName="ul">{`Allow the user to select the consent checkbox, even before filling in any information.`}</li>
      <li parentName="ul">{`Tapping anywhere on the screen dismisses the keyboard`}</li>
      <li parentName="ul">{`Now the user can scroll/bounce the card form when swiping vertically, because it's fun.`}</li>
      <li parentName="ul">{`If you don't set a banner image, we'll hide the top space reserved for it.`}</li>
    </ul>
    <h2>{`1.4.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix the Info.plist to include only one platform: `}<inlineCode parentName="li">{`iPhoneOS`}</inlineCode></li>
    </ul>
    <h2>{`1.4.0`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Add support for Swift 4.2`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added the Sweden country option`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now you can customize the final consent text with the following API:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.companyName = "Your Company Name Inc."`}</inlineCode>{` (Maximum 60 characters)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.privacyURL = "https://yourcompany.com/privacyURL"`}</inlineCode>{` (must be a valid URL)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.deleteInstructions = "Your delete instructions"`}</inlineCode>{` (Maximum 60 characters)
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the data above is not valid, the UI will not be displayed and you will get an error.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set a default country the SDK should use with `}<inlineCode parentName="p">{`Fidel.country = .unitedKingdom`}</inlineCode>{`. When you set a default country, the card linking screen will not show the country picker UI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add support for more test cards. Anything with the following format:`}</p>
        <p parentName="li">{`  Visa: `}<em parentName="p">{`4444000000004***`}</em></p>
        <p parentName="li">{`  Mastercard: `}<em parentName="p">{`5555000000005***`}</em></p>
      </li>
    </ul>
    <h2>{`1.3.7`}</h2>
    <ul>
      <li parentName="ul">{`Added the Canada country option`}</li>
    </ul>
    <h2>{`1.3.6`}</h2>
    <ul>
      <li parentName="ul">{`Add support for American Express test cards`}</li>
    </ul>
    <h2>{`1.3.5`}</h2>
    <ul>
      <li parentName="ul">{`Hidden the PayPal logo in the card scanning UI.`}</li>
      <li parentName="ul">{`Notifying in the `}<inlineCode parentName="li">{`onCardLinkFailedCallback`}</inlineCode>{` when the user cancels card linking. The `}<inlineCode parentName="li">{`error.code`}</inlineCode>{` is `}<inlineCode parentName="li">{`user-canceled`}</inlineCode>{`. The error message is `}<inlineCode parentName="li">{`User canceled card linking.`}</inlineCode></li>
      <li parentName="ul">{`Hidden the "select country" bar on all-screen iPhones.`}</li>
      <li parentName="ul">{`Added the Japan country option.`}</li>
      <li parentName="ul">{`Disabled CardIO manual card details entry forms.`}</li>
      <li parentName="ul">{`Improved testing mode user experience.`}</li>
      <li parentName="ul">{`Allow the user to select the consent checkbox, even before filling in any information.`}</li>
      <li parentName="ul">{`Tapping anywhere on the screen dismisses the keyboard`}</li>
      <li parentName="ul">{`Now the user can scroll/bounce the card form when swiping vertically, because it's fun.`}</li>
      <li parentName="ul">{`If you don't set a banner image, we'll hide the top space reserved for it.`}</li>
    </ul>
    <h2>{`1.3.4`}</h2>
    <ul>
      <li parentName="ul">{`Fix the Info.plist to include only one platform: `}<inlineCode parentName="li">{`iPhoneOS`}</inlineCode></li>
    </ul>
    <h2>{`1.3.3`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Supported in Swift 4.1 projects`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added the Sweden country option`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now you can customize the final consent text with the following API:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.companyName = "Your Company Name Inc."`}</inlineCode>{` (Maximum 60 characters)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.privacyURL = "https://yourcompany.com/privacyURL"`}</inlineCode>{` (must be a valid URL)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.deleteInstructions = "Your delete instructions"`}</inlineCode>{` (Maximum 60 characters)
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the data above is not valid, the UI will not be displayed and you will get an error.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set a default country the SDK should use with `}<inlineCode parentName="p">{`Fidel.country = .unitedKingdom`}</inlineCode>{`. When you set a default country, the card linking screen will not show the country picker UI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add support for more test cards. Anything with the following format:`}</p>
        <p parentName="li">{`  Visa: `}<em parentName="p">{`4444000000004***`}</em></p>
        <p parentName="li">{`  Mastercard: `}<em parentName="p">{`5555000000005***`}</em></p>
      </li>
    </ul>
    <h2>{`1.3.2`}</h2>
    <ul>
      <li parentName="ul">{`Compiled the SDK with bitcode enabled`}</li>
    </ul>
    <h2>{`1.3.1`}</h2>
    <ul>
      <li parentName="ul">{`Removed the CardIO Pod from podspec dependencies of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.3.0`}</h2>
    <p>{`Compiled with Swift 4.1.2.`}</p>
    <ul>
      <li parentName="ul">{`Added USA country option.`}</li>
      <li parentName="ul">{`Made the SDK available for Objective-C projects.`}</li>
      <li parentName="ul">{`Made the Pod available for the public, through the Cocoapods trunk.`}</li>
      <li parentName="ul">{`Made it easier to setup and use the Pod.`}</li>
      <li parentName="ul">{`Made the CardIO Pod an external dependency of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.2.8`}</h2>
    <ul>
      <li parentName="ul">{`Added the Canada country option`}</li>
    </ul>
    <h2>{`1.2.7`}</h2>
    <ul>
      <li parentName="ul">{`Add support for American Express test cards`}</li>
    </ul>
    <h2>{`1.2.6`}</h2>
    <ul>
      <li parentName="ul">{`Hidden the PayPal logo in the card scanning UI.`}</li>
      <li parentName="ul">{`Notifying in the `}<inlineCode parentName="li">{`onCardLinkFailedCallback`}</inlineCode>{` when the user cancels card linking. The `}<inlineCode parentName="li">{`error.code`}</inlineCode>{` is `}<inlineCode parentName="li">{`user-canceled`}</inlineCode>{`. The error message is `}<inlineCode parentName="li">{`User canceled card linking.`}</inlineCode></li>
      <li parentName="ul">{`Hidden the "select country" bar on all-screen iPhones.`}</li>
      <li parentName="ul">{`Added the Japan country option.`}</li>
      <li parentName="ul">{`Disabled CardIO manual card details entry forms.`}</li>
      <li parentName="ul">{`Improved testing mode user experience.`}</li>
      <li parentName="ul">{`Allow the user to select the consent checkbox, even before filling in any information.`}</li>
      <li parentName="ul">{`Tapping anywhere on the screen dismisses the keyboard`}</li>
      <li parentName="ul">{`Now the user can scroll/bounce the card form when swiping vertically, because it's fun.`}</li>
      <li parentName="ul">{`If you don't set a banner image, we'll hide the top space reserved for it.`}</li>
    </ul>
    <h2>{`1.2.5`}</h2>
    <ul>
      <li parentName="ul">{`Fix the Info.plist to include only one platform: `}<inlineCode parentName="li">{`iPhoneOS`}</inlineCode></li>
    </ul>
    <h2>{`1.2.4`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Supported in Swift 4.0 projects`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added the Sweden country option`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now you can customize the final consent text with the following API:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.companyName = "Your Company Name Inc."`}</inlineCode>{` (Maximum 60 characters)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.privacyURL = "https://yourcompany.com/privacyURL"`}</inlineCode>{` (must be a valid URL)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.deleteInstructions = "Your delete instructions"`}</inlineCode>{` (Maximum 60 characters)
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the data above is not valid, the UI will not be displayed and you will get an error.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set a default country the SDK should use with `}<inlineCode parentName="p">{`Fidel.country = .unitedKingdom`}</inlineCode>{`. When you set a default country, the card linking screen will not show the country picker UI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add support for more test cards. Anything with the following format:`}</p>
        <p parentName="li">{`  Visa: `}<em parentName="p">{`4444000000004***`}</em></p>
        <p parentName="li">{`  Mastercard: `}<em parentName="p">{`5555000000005***`}</em></p>
      </li>
    </ul>
    <h2>{`1.2.3`}</h2>
    <ul>
      <li parentName="ul">{`Compiled the SDK with bitcode enabled`}</li>
    </ul>
    <h2>{`1.2.2`}</h2>
    <ul>
      <li parentName="ul">{`Removed the CardIO Pod from podspec dependencies of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.2.1`}</h2>
    <p>{`Compiled with Swift 4.0.2.`}</p>
    <ul>
      <li parentName="ul">{`Added USA country option.`}</li>
      <li parentName="ul">{`Made the SDK available for Objective-C projects.`}</li>
      <li parentName="ul">{`Made the Pod available for the public, through the Cocoapods trunk.`}</li>
      <li parentName="ul">{`Made it easier to setup and use the Pod.`}</li>
      <li parentName="ul">{`Made the CardIO Pod an external dependency of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.2.0`}</h2>
    <p>{`Migrated to Swift 4.0.`}</p>
    <h2>{`1.1.8`}</h2>
    <ul>
      <li parentName="ul">{`Added the Canada country option`}</li>
    </ul>
    <h2>{`1.1.7`}</h2>
    <ul>
      <li parentName="ul">{`Add support for American Express test cards`}</li>
    </ul>
    <h2>{`1.1.6`}</h2>
    <ul>
      <li parentName="ul">{`Hidden the PayPal logo in the card scanning UI.`}</li>
      <li parentName="ul">{`Notifying in the `}<inlineCode parentName="li">{`onCardLinkFailedCallback`}</inlineCode>{` when the user cancels card linking. The `}<inlineCode parentName="li">{`error.code`}</inlineCode>{` is `}<inlineCode parentName="li">{`user-canceled`}</inlineCode>{`. The error message is `}<inlineCode parentName="li">{`User canceled card linking.`}</inlineCode></li>
      <li parentName="ul">{`Hidden the "select country" bar on all-screen iPhones.`}</li>
      <li parentName="ul">{`Added the Japan country option.`}</li>
      <li parentName="ul">{`Disabled CardIO manual card details entry forms.`}</li>
      <li parentName="ul">{`Improved testing mode user experience.`}</li>
      <li parentName="ul">{`Allow the user to select the consent checkbox, even before filling in any information.`}</li>
      <li parentName="ul">{`Tapping anywhere on the screen dismisses the keyboard`}</li>
      <li parentName="ul">{`Now the user can scroll/bounce the card form when swiping vertically, because it's fun.`}</li>
      <li parentName="ul">{`If you don't set a banner image, we'll hide the top space reserved for it.`}</li>
    </ul>
    <h2>{`1.1.5`}</h2>
    <ul>
      <li parentName="ul">{`Fix the Info.plist to include only one platform: `}<inlineCode parentName="li">{`iPhoneOS`}</inlineCode></li>
    </ul>
    <h2>{`1.1.4`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Supported in Swift 3.0 projects`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added the Sweden country option`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now you can customize the final consent text with the following API:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.companyName = "Your Company Name Inc."`}</inlineCode>{` (Maximum 60 characters)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.privacyURL = "https://yourcompany.com/privacyURL"`}</inlineCode>{` (must be a valid URL)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.deleteInstructions = "Your delete instructions"`}</inlineCode>{` (Maximum 60 characters)
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the data above is not valid, the UI will not be displayed and you will get an error.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set a default country the SDK should use with `}<inlineCode parentName="p">{`Fidel.country = .unitedKingdom`}</inlineCode>{`. When you set a default country, the card linking screen will not show the country picker UI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add support for more test cards. Anything with the following format:`}</p>
        <p parentName="li">{`  Visa: `}<em parentName="p">{`4444000000004***`}</em></p>
        <p parentName="li">{`  Mastercard: `}<em parentName="p">{`5555000000005***`}</em></p>
      </li>
    </ul>
    <h2>{`1.1.3`}</h2>
    <ul>
      <li parentName="ul">{`Compiled the SDK with bitcode enabled`}</li>
    </ul>
    <h2>{`1.1.2`}</h2>
    <ul>
      <li parentName="ul">{`Removed the CardIO Pod from podspec dependencies of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.1.1`}</h2>
    <p>{`Compiled with Swift 3.0.`}</p>
    <ul>
      <li parentName="ul">{`Added USA country option.`}</li>
      <li parentName="ul">{`Made the SDK available for Objective-C projects.`}</li>
      <li parentName="ul">{`Made the Pod available for the public, through the Cocoapods trunk.`}</li>
      <li parentName="ul">{`Made it easier to setup and use the Pod.`}</li>
      <li parentName="ul">{`Made the CardIO Pod an external dependency of the Fidel SDK.`}</li>
    </ul>
    <h2>{`1.1.0`}</h2>
    <p>{`Migrated to Swift 3.2.`}</p>
    <h2>{`1.0.4`}</h2>
    <p>{`Features:`}</p>
    <ul>
      <li parentName="ul">{`Added option to use SDK in test environment using test card numbers`}</li>
    </ul>
    <p>{`Improvements:`}</p>
    <ul>
      <li parentName="ul">{`Replaced UI assets`}</li>
      <li parentName="ul">{`Refactored comments`}</li>
      <li parentName="ul">{`Updated README.md`}</li>
      <li parentName="ul">{`Included CHANGELOG.md`}</li>
    </ul>
    <h2>{`1.0.3`}</h2>
    <p>{`Bugfixes:`}</p>
    <ul>
      <li parentName="ul">{`Fixed new callback name tests`}</li>
    </ul>
    <h2>{`1.0.2`}</h2>
    <p>{`Improvements:`}</p>
    <ul>
      <li parentName="ul">{`Replaced cardId and error callbacks with LinkResult and LinkError`}</li>
    </ul>
    <h2>{`1.0.1`}</h2>
    <p>{`Improvements:`}</p>
    <ul>
      <li parentName="ul">{`Updated Example app`}</li>
    </ul>
    <p>{`Bugfixes:`}</p>
    <ul>
      <li parentName="ul">{`Fixed card.io camera nil image`}</li>
    </ul>
    <h2>{`1.0.0`}</h2>
    <p>{`Features:`}</p>
    <ul>
      <li parentName="ul">{`Added camera card scanning`}</li>
      <li parentName="ul">{`Added API link card endpoints`}</li>
      <li parentName="ul">{`Banner image customisation`}</li>
      <li parentName="ul">{`Added country code picker`}</li>
      <li parentName="ul">{`API tests`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      